import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Routes } from '../constants/Routes';
import Title from '../components/title';
import renderHtml from '../utils/renderHtml';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const PREISE_QUERY = graphql`
  {
    allContentfulPreise {
      nodes {
        id
        titel
        beschreibung {
          json
        }
        kinderTabelle {
          tableData
        }
        erwachseneTabelle {
          tableData
        }
      }
    }
  }
`;

type Content = {
  id: string;
  title: string;
  beschreibung: any;
  kinderTable: string[][];
  erwachseneTabelle: string[][];
};

const CieAhaPlusPage: FC = () => {
  const data: Content = useStaticQuery(PREISE_QUERY).allContentfulPreise.nodes.map((n: any) => ({
    id: n.id,
    titel: n.titel,
    beschreibung: n.beschreibung,
    kinderTable: n.kinderTabelle.tableData,
    erwachseneTabelle: n.erwachseneTabelle.tableData,
  }))[0];

  const renderTable = (input: string[][], kategorie: string) => (
    <table className={`${kategorie === "erwachsene" ? "bg-p5" : "bg-p1"} text-black`}>
      <tr>
        {input[0].map((n, i) => (
          <th key={n + i}>{n}</th>
        ))}
      </tr>
      {input.slice(1).map((row, i) => (
        <tr key={i}>
          {row.map((n, i) => (
            <td key={n + i}>{n}</td>
          ))}
        </tr>
      ))}
    </table>
  );

  return (
    <Layout location={Routes.PREISE}>
      <SEO title="cie. aha! plus" />
      <div className="mt-8">
        <Title className="mb-4 text-center">Preise pro Semster</Title>
        <div className="transition-all transition-250 hover:shadow-xl max-w-6xl rounded  shadow-lg mx-auto mb-12 w-full p-4 md:p-8 markdown-body v">
          <h2>Erwachsene</h2>
          {renderTable(data.erwachseneTabelle, "erwachsene")}
          <h2>Kinder, Jugendliche, Studenten</h2>
          {renderTable(data.kinderTable, "kinder")}
          <div className="mt-8 markdown-body v">{documentToReactComponents(data.beschreibung.json)}</div>
        </div>
      </div>
    </Layout>
  );
};

export default CieAhaPlusPage;
